<div class="w-full flex flex-row justify-center">
  <a
    routerLink="/content/122-kreatywny-weekend-czekolove"
    class="relative flex w-full justify-center max-w-100 sm:max-w-240 md:max-w-480 aspect-video md:aspect-[3.2/1]">
    <img
      class="flex w-full max-w-100 aspect-video sm:hidden"
      ngSrc="https://manzuko.b-cdn.net/assets/images/b/491.jpg?aspect_ratio=16:9&quality=75"
      alt="Kreatywny Weekend - CZEKOLOVE"
      fill
      priority />
    <img
      class="hidden w-full max-w-240 aspect-video sm:flex md:hidden"
      ngSrc="https://manzuko.b-cdn.net/assets/images/b/490.jpg?aspect_ratio=16:9&quality=75"
      alt="Kreatywny Weekend - CZEKOLOVE"
      fill
      priority />
    <img
      class="hidden w-full max-w-480 aspect-[3.2/1] md:flex"
      ngSrc="https://manzuko.b-cdn.net/assets/images/b/489.jpg?aspect_ratio=32:10&quality=75"
      alt="Kreatywny Weekend - CZEKOLOVE"
      fill
      priority />
  </a>
</div>

